#modalTeamUsers {

    .modal-header {

        .ri-xl {
            vertical-align: auto;
            line-height: 1.2em;
        }
    }

    #content {

        .body {

            p {
                margin: 0 !important;
            }
        }
    }
}
