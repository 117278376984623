
#checkPropriety{
    margin-left: 3rem;
    margin-top: 2.5rem;
}

#toolsFleet
{
/*     display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap; */
    width: max-content;
}
