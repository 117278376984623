.custom-btn-max-min{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    color: gray;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 3px;
    transition: ease-in 0.3s;

    &:hover{
        background-color: #d4d4d4;
    }
}

.btn-action{
    cursor: pointer;
}

.btn-action-edit{
    cursor: pointer;
    color: #17a2b8;
    transition: ease-in 0.3s;

    &:hover{
       color: #007486;
    }
}

.header-details{
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 10px;
    background-color: #E6E6E6;
    border-radius: 5px 5px 0px 0px;

    .headertitle{
        display: flex;
        height: 100%;
        font-size: 25px;
    }

    .group-btn-action{
        margin-left: auto;
    }
}

.genealModal
{
    .modal-dialog {
        max-width: 1000px !important;
        margin: 1.75rem auto;
    }
}

#changeLocationModal,
#addFile,
#updateFileGalpModal
{
    margin-top: 200px;
}




.custom-multi-select{
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-y: scroll;
    height: 300px;
    padding: 5px;
    border: 1px solid #D8D8D8;
    border-radius: 5px;

}

