
#legalProcessModal,
#editLegalProcessModa,
#authorModal,
#intervenorModal,
#editAuthorModal,
#addFile
{
    .modal-dialog {
        max-width: 1000px !important;
        margin: 1.75rem auto;
    }
}


#removeIntervenorModal,
#generalAlertModal,
#updateFileGalp
{
    .modal-dialog {
        max-width: 600px !important;
        margin: 1.75rem auto;
    }
}

.borderBotton-custom{
    border-bottom: 1px solid #E6E6E6;
    width: 100%;
    margin-top: 3px;
}

#createSubItemModal
{
    .modal-dialog {
        padding-top: 200px;
        max-width: 600px !important;
        margin: 1.75rem auto;
    }
}
