#login-page {

    #entry-form {

        #p {
            font-family: 'password'
        }
    }

    #elevador4dummies {

        &.collapsed {

            .chevron {

                i {
                   transition: transform .4s;
                   transform: rotate(180deg);
                }
            }
        }
    }
}
