&.autocomplete-search {

    #autocomplete-list {
        max-height: 250px;
        overflow-y: scroll;
        box-shadow: 0px 1px 8px -4px black;
        background: $white;
        z-index: 2;
        width: 100%;
        // display: none;

        &.show {
            display: initial !important;
        }

        ul {
            padding: 5px 15px;
            width: 100%;
            margin: 0 auto !important;
            list-style: none;

            .loading {
                width: 100%;
                // display: none;

                .spinner {
                    @include spinner(40px);
                }

                &.show {
                    display: initial !important;
                }
            }

            li {
                padding: 5px 0;

                &:first-child {
                    padding-top: 0
                }

                &:last-child {
                    padding-bottom: 0;
                }

                &>span {
                    border-radius: 3px;
                    font-size: 11px;
                    padding: 1px;
                    margin-right: 10px;
                    position: relative;
                    top: -3px;

                    &.locality {
                        border: 1px solid blueviolet;
                        color: blueviolet;
                        background-color: rgba(138, 43, 226, .1);
                    }

                    &.street {
                        border: 1px solid rgb(36, 98, 214);
                        color: rgb(36, 98, 214);
                        background-color: rgba(36, 98, 214, .1);
                    }
                    &.perish {
                        border: 1px solid rgb(199, 174, 10);
                        color: rgb(199, 174, 10);
                        background-color: rgba(217, 192, 30, .1);
                    }
                }
            }
        }
    }


    .adjacent-label {
        margin-top: 1rem;
    }

    #selection-list {
        column-count: 3;

        .selection-card {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            width: 100%;
            animation-name: flash;
            animation-duration: 3s;
            animation-fill-mode: both;

            .card-header {
                position: relative;

                a {
                    position: absolute;
                    right: 10px
                }
            }

            [data-title]:hover:after {
                opacity: 1;
                transition: all 0.1s ease 0.5s;
                visibility: visible;
            }


            span{

                i{
                    position: absolute;
                    //float: left;
                    cursor: pointer;
                    color: #DBA901;
                    margin-top: -6.6rem;
                    margin-left: 1rem;
                    transition: 0.3s ease-in ;
                }
            }

            p{
                display: none;
                position: relative;
                cursor: pointer;
                width: 100%;
                font-size: .8rem;
                float: left;
                padding: 5px;
                border-radius: 5px;
                border: none;
                transition: 0.3s ease-in ;
            }

        }
    }
}
