#vehicleSelectd{
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #D8D8D8;
    padding: 5px;
}

#noVehicles p{
    align-items: center;
    text-align: center;
    margin-top: 10px;
    color: gray;
}

#cardRegister{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    background-color: #2E2E2E;
    color: white;
    border: none;
    border-radius: 100px;
    width: 110px;
    padding: 5px;
    margin: 5px;
    box-shadow: 0px 0px 2px 1px #D8D8D8;
}

#cardRegister span{
    cursor: pointer;
    transition: ease-in;
}

#cardRegister i{
    color: white;
}

#cardRegister i:hover{
    color: #FFBF00;
}
