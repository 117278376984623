@mixin ajax_spinner {
    position: relative;

    .spinner {
        position: absolute;
        right: 10px;

        .spinner-outer {
            width: 40px;
            position: absolute;
        }

        .spinner-inner {
            width: 20px;
            position: absolute;
        }
    }
}

@mixin square($size) {
    width: $size;
    height: $size;
}

@mixin spinner($size) {
        margin: 0 auto;
        border-radius: 50%;
        border: 5px solid $lightgray;
        border-bottom: 5px solid $blue;
        @include square($size);
        animation: spinner 2s linear infinite;    
}
