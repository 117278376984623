.object-table {

    thead {

        th {

            &:nth-last-of-type(-n+2) {
                text-align: center;
            }
        }
    }

    tbody {

        tr {

            &:nth-child(even) {
                background: rgba(0, 0, 0, 0.045);
            }
        }
    }

    .actions {
        text-align: center;
    }
}

table {

    &.table {

        &.table-striped {

            & > tbody {

                & > tr {

                    &:nth-of-type(even) {
                        background-color: rgba(0, 0, 0, 0.05); // Choose your own color here
                    }

                    &:nth-of-type(odd) {
                        background-color: transparent; // Choose your own color here
                    }

                    &:hover {
                        background-color: #dbe7ff;
                    }
                }
            }
        }
    }

    tr {

        td {
            vertical-align: middle !important;

            &.actions {
                position: relative;

                a {
                    top: 0;
                    bottom: 0;
                    position: relative;
                }
            }
        }
    }

    thead {

        th {

            &.actions,
            &.info,
            &.warn,
            &.error {
                min-width: 55px;
            }
        }
    }
}
