// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap
// @import url('https://cdn.jsdelivr.net/gh/djibe/material@4.4.1-beta.2/css/material.min.css');
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css';
@import url("https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.css");
@import '~laraform/src/themes/default/scss/theme.scss';

// Datatables
// @import '~datatables.net-dt/css/jquery.dataTables.min.css';
@import url("https://cdn.datatables.net/1.10.21/css/dataTables.bootstrap4.min.css");

// Webfonts
@import '~@fortawesome/fontawesome-free/css/all';
@import '~remixicon/fonts/remixicon';
@import '~leaflet/dist/leaflet.css';

@font-face {
    font-family: 'password';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/password.ttf');
}

// Quill.js WYSIWYG Editor
// @import '~quill/dist/quill.core.css';
// @import '~quill/dist/quill.snow.css';

// App
@import 'helpers/variables';
@import 'helpers/animations';
@import 'helpers/mixins';

@import 'partials/partials';

@import 'components/components';

@import 'layout/layout';

@import 'pages/pages';

@import 'page_groups/page_groups';

@import 'pages/sms.scss';

@import 'pages/fleet.scss';


@import 'components/validations';

@import 'ilicits/legalprocess.scss';
@import 'ilicits/auto.scss';
