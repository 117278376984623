@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes flash {
    0%, 50%, 100% {
       opacity: 1;
    }
    25%, 75% {
       opacity: .5;
    }
 }