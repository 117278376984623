#previousStep{
    cursor: pointer;

    i{
        transition: 0.2s ease-in;
    }
    i:hover{
        color: #DF0101
    }
}

.backtrack{
    cursor: pointer;

    i{
        transition: 0.2s ease-in;
    }
    i:hover{
        color: #a38500
    }
}


.cancelprocess{

    cursor: pointer;
    i{
        transition: 0.2s ease-in;
    }
    i:hover{
        color: #a80000
    }
}

#nextStep{
    cursor: pointer;

    i{
        transition: 0.2s ease-in;
    }

    i:hover{
        color: #088A08;
    }
}


.currentstep{
    cursor: pointer;

    i{
        transition: 0.2s ease-in;
    }

    i:hover{
        color: #088A08;
    }
}


#occurrenceImage{
/*     display: flex;
    justify-content: center; */
    .card{
        background-color: #E6E6E6;
        border: none;
    }



    #featured{

        #featuredHeader{
/*             position: absolute; */
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 100;
            color: white;
            padding: 10px;
            width: 100%;
            background-color: white;
            border-radius: 5px 5px 0px 0px;
            color: black;
            //background: rgb(0,0,0);
            //background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.44870448179271705) 68%, rgba(1,17,20,0) 100%);
        }

        #featuredImage{
            position: relative;
        }


        .prev{
            position: absolute;
            z-index: 10000;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            height: 100%;
            padding: 10px;
            color: white;

            span{
                cursor: pointer;
            }
        }

        .next{
            position: absolute;
            display: flex;
            z-index: 10000;
            justify-content: center;
            float: right;
            align-items: center;
            flex-wrap: nowrap;
            height: 100%;
            padding: 10px;
            color: white;
            margin-left: auto;

            span{
                cursor: pointer;
            }
        }

        img{
            max-height: 600px;
        }
    }

    #closeFeatured{
        cursor: pointer;
        float: right;
        color: #adacac;
        transition: 0.2s ease-in;

        &:hover{
            color: #565454;
        }
    }
}




.gallery{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
   /*  border: 1px solid red */;
}

.ilicitimg{
    max-height: 100px;
}

.images{
    display: inline-block;
    box-sizing: border-box;
/*     padding: 5px; */
/*     width: 100px; */

    img {
        width: 200px;
        cursor: pointer;
        transition: 0.3s ease-in;
        &:hover{
            box-shadow: 0px 3px 3px 2px #6f6d6d;
        }
    }
}

.nagavatio-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
/*     position: fixed; */
    width: 100%;
    margin-top: 60px;
/*     padding: 10px; */
    /* border: 1px solid red; */
    text-align: center;
}


.comment{
    cursor: pointer;
    color: #007bff;
    &:hover{
        color: #005fc4;
    }
}

.addcomment{
    cursor: pointer;
    color: #6c757d;
    &:hover{
        color: #414242;
    }
}

.conclude{
    cursor: pointer;
    &:hover{
        color: #088A08;
    }
}

.editProcess{
    cursor: pointer;
    &:hover{
        color: #0489B1;
    }
}


#title-auto{
    color: #00538a;
}


.points-auto p{
    color: #1a6ba2;
    margin-bottom: 0px;
}


.borderBotton-auto{
    border: 1px solid #1a6ba2;
    width: 100%;
    margin-top: 1px;
}

.ponts-content{
    background-color: #E6E6E6;
    padding-left: 30px;
}

.ponts-content p{
    padding-top: 15px;
    padding-bottom: 15px;
}

.embed-pdf{
    cursor: pointer;
}

#occurrenceFiles{
    margin-top: -80px;
    padding: 10px;
    .card{
        background-color: #E6E6E6;
        border: none;
    }
}

.occurrence-item-files{
    border-radius: 10px;
    width: 100%;
    margin: 5px;

    embed{
        width: 100%;
        height: 800px;
        margin-top: -80px;
        border-radius: 10px;
        transition: 0.3s ease-in;
    }
}


#closepdf{
    z-index: 100;
    margin-top: -10px;
}

#loadPDF{
    z-index: 0;
}

.fileitem{
    cursor: pointer;
    transition: 0.3s ease-in;
    &:hover{
        box-shadow: 0px 0px 2px 3px #bbbbbb;
    }
}


#itemsOnModal{
    display: inline-flex;
    flex-wrap: nowrap;
    width: 100%;
    #cardItem{
        display: inline-flex;
    }
}



