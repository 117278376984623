#timesheet {

    table {
        width: 100%;

        // tr {

        //     &.odd {

        //         td {
        //             background-color: #d6d6d6;
        //         }
        //     }

        //     td, th {
        //         min-width: 70px;
        //         white-space: nowrap;
        //         font-size: 14px;

        //         &.w60 {
        //             width: 60px;
        //         }

        //         &.w90 {
        //             width: 90px;
        //         }

        //         &.w300 {
        //             width: 300px;
        //         }
        //     }
        // }

        // tbody {

        //     tr {

        //         &.no-border {

        //             td, th {
        //                 border: none !important;
        //             }
        //         }

        //         &:not(:last-child) {

        //             td, th {

        //                 &:not(:last-child) {
        //                     border-right: 1px solid #dee2e6;
        //                 }

        //                 &:last-child {
        //                     border-left: 1px solid #dee2e6;
        //                     white-space: normal;
        //                     word-wrap: break-word;
        //                 }

        //             }
        //         }

        //         &.saturday {

        //             td {
        //                 background-color: rgb(255, 245, 222) !important;
        //             }
        //         }

        //         &.sunday {

        //             td {
        //                 background-color: rgb(185, 185, 185) !important;
        //             }
        //         }
        //     }
        // }
    }
}
