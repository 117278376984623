input.disabled {
    pointer-events:none;
    background-color:#ffffff;
  }

input[type="file" i] {
    padding: 4px;
}

select.disabled {
    pointer-events:none;
    background-color:#ffffff;
 }

 textarea.disabled {
    pointer-events:none;
    background-color:#ffffff;
 }

.restore
{
    color: #17a2b8;
}

.restore:hover
{
    color: #007081;
}

.messageerror{
    color: red;
}

th, td { white-space: nowrap; }
    div.dataTables_wrapper {
        margin: 0 auto;
}

.btn-resolved{
    background-color: #00708100;
    border:  none;
    font-size: 1.6rem;
    margin-left: 35px;
    cursor: pointer;
}

.btn-solved{
    background-color: #00708100;
    border:  none;
    font-size: 1.6rem;
    margin-left: 35px;
}

.btn-assigned{
    background-color: #00708100;
    border:  none;
    font-size: 1.6rem;
    margin-left: 35px;
    cursor: pointer;
}

.btn-notAssigned{
    background-color: #00708100;
    border:  none;
    font-size: 1.6rem;
    margin-left: 35px;
    color: #C2C2C2
}


.btn-active{
    background-color: #00708100;
    border:  none;
    font-size: 1.3rem;
    margin-left: 35px;
    cursor: pointer;
}

.btn-inactive{
    background-color: #00708100;
    border:  none;
    font-size: 1.3rem;
    margin-left: 35px;
    color: #C2C2C2
}


.btn-icon-status{
    background-color: #00708100;
    border:  none;
    font-size: 1.3rem;
    margin-left: 35px;
}


.centralize{
    background-color: #00708100;
    border:  none;
    margin-left: 40%;
}


.btn-check
{
    display: inline-block;
    align-items: center;
    text-align: center;
    background-color: #007bff;
    padding: 4px;
    font-size: 14px;
    border-radius: 3px;
    color: white;
    border: none;
    height: 31px;
}

.btn-check #situation{
    cursor: pointer;
    border: none;
}

.btn-check input[type=radio], input[type=checkbox] {
    box-sizing: border-box;
    border: none;
    padding: 0;
}


