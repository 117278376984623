@import 'errors';
@import 'floating_labels';
@import 'animations';

// .dropdown {

//     &:hover {

//         .dropdown-menu {
//             display: block;
//         }
//     }
// }

.opacity-0 {
    opacity:0!important;
}

.opacity-1 {
    opacity:0.2!important;
}

.opacity-2 {
    opacity:0.4!important;
}

.opacity-3 {
    opacity:0.6!important;
}

.opacity-4 {
    opacity:.8!important;
}

.opacity-5 {
    opacity:1!important;
}

.bg-white-op-0 {
    background: rgba(255, 255, 255, 0)
}

.bg-white-op-1 {
    background: rgba(255, 255, 255, 0.2)
}

.bg-white-op-2 {
    background: rgba(255, 255, 255, 0.4)
}

.bg-white-op-3 {
    background: rgba(255, 255, 255, 0.6)
}

.bg-white-op-4 {
    background: rgba(255, 255, 255, 0.8)
}

.bg-white-op-5 {
    background: rgba(255, 255, 255, 1)
}

.bg-flamingo {
    background: #dc112512 !important;
}

.fill-parent {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.spinner-center {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.modal-spinner-200 {
    padding: 19%;
}

.min-h-100 {
    min-height: 100px;
}

.min-h-200 {
    min-height: 200px;
}

.min-h-300 {
    min-height: 300px;
}

.ri-lg {
    line-height: unset !important;
    vertical-align: bottom;
}

.table-sm {

    tr {

        td {
            line-height: 1.3333em;
        }
    }
}

.popover-danger {
    .popover-danger {
        background-color: #dc112512;
        border-color: $danger;
    }

    .popover-danger.right .arrow:after {
        border-right-color: $danger;
    }
}

.cursor-info {
    cursor: help !important;
}

.show-password {
    position: absolute;
    right: 20px;
    top: 57%;
}

.dropdown-menu {

    &.min-w-0 {
        min-width: 0 !important;
    }
}

.spining {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 2.5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 2.5s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -o-transition: rotate(360deg);
    transition: rotate(360deg)
}
