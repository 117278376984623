&.multiselect-listbox {

    #btnContainer {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 70px;
        width: 35px;
    }
}
