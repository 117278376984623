#calls-dashboard {
    #datatable-calls_filter {
        display: none;
    }

    #clearDate {
        right: 5px;
        top: 20%;
        width: 20px;
        height: 20px;
    }

    #graphs-header {

        &.collapsed {

            div {

                &.chevron {

                    i {
                        transition: transform .4s;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    #numberedMetrics {

        .value-set {

            .icon {
                font-size: 2em;
            }

            .value {
                font-size: 1.5em;
            }

            .title {
                font-size: .8em;
            }
        }
    }
}
