#icon {
    text-align: center;
  }

.order {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 16px;
}

.order #sortDown{
    cursor: pointer;
}

.order #sortUp{
    cursor: pointer;
}
