#asset-create,
#asset-edit {

    #inputInvoice,
    #inputManual,
    #inputPictures {

        &.form-control {
            padding-bottom: 2.2rem;
        }
    }

    .custom-checkbox-padding {
        padding-top: 2.2rem;
    }

    #pictures {
        
        #template {

            #removePicture {
                display: none;
            }
        }
    }
}
