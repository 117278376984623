#itemSelected{
    display: flex;
    flex-wrap: wrap;
    border-top: 0px solid #D8D8D8;
    padding: 5px;
}

#noItems p{
    align-items: center;
    text-align: center;
    margin-top: 10px;
    color: gray;
}

#cardItem{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    background-color: #2E2E2E;
    color: white;
    border: none;
    border-radius: 100px;
    width: auto;
    padding: 5px;
    margin: 5px;
    box-shadow: 0px 0px 2px 1px #D8D8D8;
}

#cardItem span{
    cursor: pointer;
    transition: ease-in;
}

#cardItem i{
    color: white;
    margin-left: 5px;
}

#cardItem i:hover{
    color: #FFBF00;
}

#add_form{
    font-size: large;
    cursor: pointer;
    color: #0174DF;
}

#remove_form{
    font-size: large;
    cursor: pointer;
    color: #DF0101;
    margin-right: 10px;
}

.newWitnessForm{
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
    box-shadow: 0 0 2px 2px #F2F2F2;
}
