@import 'tables';
@import 'header';

a {
    color: rgb(163, 163, 163);
    text-decoration: none !important;

    &:hover {
        color: gray;
    }

    &.disable,
    &.delete {
        color: $danger;

        &:hover {
            color: $hoverdanger;
        }
    }

    &.enable {
        color: $green;

        &:hover {
            color: $hovergreen
        }
    }
}

.has-spinner {
    @include ajax_spinner;
}

.disabled {
    cursor: not-allowed;
}

.ql-container {
    resize: vertical;
    overflow-y: scroll;
}

.locality-group{
    margin-top: 10px;
}

.cutom-border-button{
    border-bottom: 1px solid #F2F2F2;
    width: 100%;
    margin-bottom: 10px;
}


.btn-usageGuide
{
    border: none;
    background-color: #F2F2F2;
    color: #17a2b8;
    transition: 0.3s ease-in;
    &:hover{
        color: #00606e;
    }
}
