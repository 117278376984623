#original-work {

    a {

        &.remove-work {
            display: none;
        }
    }
}

#report-lines {

    tr {

        &:first-child {

            .actions {

                #removeRow {
                    display: none;
                }
            }
        }

        td {

            &.quantity {
                width: 15%;
            }

            &.date {
                width: 25%;
            }

            input {

                &#inputDatetime {
                    width: 87%;
                }
            }
        }
    }
}

div {

    &[id^="work-"] {
        cursor: pointer;

        &.collapsed {

            div {

                &.chevron {

                    i {
                        transition: transform .4s;
                        transform: rotate(180deg);
                    }
                }
            }
        }

        div {

            &.chevron {

                i {
                    transition: transform .4s;
                }
            }
        }
    }

    &.card {

        table {
            border-collapse: collapse;
            border: none;

            thead {
                border: none;

                tr {
                    border-top: none;

                    th {
                        border-width: 1px;
                        border-top: none;
                    }
                }
            }
        }
    }
}

#modalComment {

    .modal-header {

        .ri-xl {
            vertical-align: auto;
            line-height: 1.2em;
        }
    }

    #content {

        .body {

            p {
                margin: 0 !important;
            }
        }
    }
}

#datatable-reports_filter {
    display: none;
}

#datatable-clockings-management{

    #date-filter {
        position: relative;

        #clear-date-field {
            position: absolute;
            padding: 0;
            right: 18px;
            top: 10px;
            width: 12px;
            height: 12px;
            font-size: 18px;

            i {
                padding: 0;
                cursor: pointer;
            }
        }
    }
}

#datatable-reports {

    #date-filter {
        position: relative;

        #date-field {

            &::-webkit-inner-spin-button,
            &::-webkit-calendar-picker-indicator {
                margin-right: 10px
            }
        }

        #clear-date-field {
            position: absolute;
            padding: 0;
            right: 18px;
            top: 10px;
            width: 12px;
            height: 12px;
            font-size: 18px;

            i {
                padding: 0;
                cursor: pointer;
            }
        }
    }
}
